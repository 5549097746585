import Vue from 'vue'
import Vuex from 'vuex'
import locales from "@/assets/locales.json"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        country: 'CZ',
        lang: 'cs',
        locales: locales
    },
    getters: {
        country: state => state.country,
        lang:  state  => state.lang,
        locales: state => state.locales
    }
  })

export default store