<template>
    <b-container>
        <b-row>
            <b-col cols="12"><h1>{{ locales.get('music.label') }}</h1></b-col>
        </b-row>
        <b-row>
            <b-col cols="12" v-for="(item, idx) in items" :key="`music-item-${idx}`" class="music-item">
                <b-col cols="12" lg="6" offset-lg="3">
                    <div class="text-center">
                        <b-img :src="`/music/${item.image}`"></b-img>
                        <h3>{{ item.title }}</h3>
                        <div class="music-item-album-info">{{ item.year }} - {{ item.type }}</div>
                        <b-link v-for="(link, linkIdx) in item.links" :key="`music-item-${idx}-${linkIdx}`" :href="link.link" class="listen-on-link">
                            <font-awesome-icon :icon="link.icon"/>
                        </b-link>
                    </div>
                </b-col>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
        const locales = useLocales()

        const items = locales.get('music.items').map((m, i) => {
            for (let k in m) {
                m[k] = locales.get(`music.items[${i}].${k}`)
            }
            return m
        }).sort((a, b) => {
            return parseInt(b.year) - parseInt(a.year)
        })

        return {
            items,
            locales
        }
    }
}
</script>

<style scoped>
.music-item {
    margin-bottom: 3rem;
}
.music-item:last-of-type::after {
    display: none;
}
.music-item h3 {
    margin-top: 1rem;
}
.music-item img {
    max-width: 10rem;
}
.music-item .info {
    display: flex;
    align-items: center;
}

.music-item .listen-on-link {
    padding-left: .3rem;
    padding-right: .3rem;
}
</style>