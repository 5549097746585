<template>
    <b-container>
        <b-row> 
            <b-col cols="12">
                <h1>{{ locales.get("contact.label") }}</h1>
            </b-col>
            <b-col cols="12" class="contact-item">
                <b-link :href="'tel:' + locales.get('contact.phone')">
                    <b-icon-telephone-fill/> {{ locales.get('contact.phone') }}
                </b-link>
            </b-col>
            <b-col cols="12"  class="contact-item">
                <b-link :href="'mailto:' + locales.get('contact.mail')">
                    <b-icon-envelope-fill/> {{ locales.get('contact.mail') }}
                </b-link>
            </b-col>

            <b-col cols="12" class="contact-item separator"></b-col>

            <b-col cols="12" class="contact-item" v-for="(soc, sIdx) in social" :key="'soc-contact-' + sIdx">
                <b-link :href="soc.link" target="_blank">
                    <font-awesome-icon :icon="soc.icon"/> {{ soc.description }}
                </b-link>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
        const locales = useLocales()
        const social = locales.get("contact.social").map((s, i) => {
            for (let k in s) {
                s[k] = locales.get(`contact.social[${i}].${k}`)
            }
            return s
        })
        return {
            locales,
            social
        }
    }
}
</script>

<style scoped>
.contact-item {
    padding: .5rem;
    padding-bottom: .5rem;
}
.contact-item.separator {
    min-height: 3rem;
}
</style>