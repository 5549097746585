<template>
    <b-navbar toggleable="lg" fixed="top" type="dark" variant="dark">
    
    <b-navbar-brand to="/">
      <img src="@/assets/logo.svg" class="logo d-inline-block" alt="Critical Acclaim">
      <img src="@/assets/title.svg" class="title d-none d-sm-inline-block " alt="Critical Acclaim">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="collapse-nav">
        <b-nav-item v-for="(item, i) in items" :key="'menu-item-' + i" :to="item.to">{{ item.description }}</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <!-- <b-navbar-nav class="ml-auto langs">
        <b-nav-item v-for="(lang, i) in langs" :key="'lang-' + i" href="#">{{ lang }}</b-nav-item>
      </b-navbar-nav> -->
    </b-collapse>
  </b-navbar>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
      const locales = useLocales()
      const routes = ['news', 'band', 'live', 'music', 'video', 'contact']
      const items = routes.map(r => {
        return {
          description: locales.get(`${r}.label`),
          to: r
        }
      })
      const langs = ['cs', 'en']

        return {
            items,
            langs,
            locales
        }
    }
}
</script>

<style scoped>
.logo {
    /* width: 50px; */
    height: 30px;
}
.title {    
    height: 15px;
    margin-left: 15px;
}
.navbar.bg-dark {
  background-color: transparent !important;
}
a.nav-link {
  text-transform: uppercase;
  font-weight: 400;
}
@media screen and (max-width: 991px) {
  #nav-collapse {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  #nav-collapse .langs {
    padding-top: 10%;
  }
}
</style>