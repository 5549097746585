<template>
    <b-container>
        <b-row>
            <b-col cols="12">
                <img src="@/assets/title.svg" class="title" alt="Critical Acclaim"/>
        <!-- <img src="@/assets/title_dark.svg" class="title" alt="Critical Acclaim"/> -->
            </b-col>
            <b-col cols="12">
                <p>{{ locales.get('band.info') }}</p>
            </b-col>
            <b-col cols="12" class="member" sm="4" v-for="(m, mIdx) in members" :key="'member-' + mIdx">
                <b-img class="photo" :src="`/members/${m.photo}.png`"/>
                <div class="name">{{ m.firstName }} {{ m.lastName }}</div>
                <div class="short-description">{{ m.shortDescription }}</div>
                <!-- <div class="description">{{ m.description }}</div> -->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
        const locales = useLocales()
        const members = locales.get('band.members').map((m, i) => {
            for (let k in m) {
                m[k] = locales.get(`band.members[${i}].${k}`)
            }
            return m
        })
        return {
            locales,
            members
        }
    }
}
</script>

<style scoped>
.title {
    margin-top: 4rem;
    margin-bottom: 5rem;
    max-width: 280px;
}
.member {
    padding-bottom: 15px;
}
.member .photo {
    background-color: gray;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.member .name {
    font-weight: 500;
    font-size: 1.4rem;
}
.member .short-description {
    font-size: 1.1rem;
    padding-top: .5rem;
    padding-bottom: 1rem;
}
.member .description {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
}
</style>