<template>
    <b-container>
        <b-row>
            <b-col cols="12"><h1>{{ locales.get('video.label') }}</h1></b-col>
            <b-col cols="12">
                <b-row align-content="center">
                <b-col align-self="center" cols="12" sm="6" v-for="(yt, ytIdx) in youTubeVideos" :key="'ytv-' + ytIdx">
                    <!--
                        width="560"
                        height="315"
                        -->
                    <iframe
                        class="yt-frame"
                        :src="yt.link"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        >
                        </iframe>
                </b-col>
            </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
        const locales = useLocales()

        const youTubeVideos = locales.get('video.youtube').map((v, i) => {
            for (let k in v) {
                v[k] = locales.get(`video.youtube[${i}].${k}`)
            }
            return v
        })

        return {
            locales,
            youTubeVideos
        }
    }
}
</script>

<style scoped>
.yt-frame {
    width: 100%;
    height: 315px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1.5rem;
}
</style>