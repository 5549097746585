<template>
  <div id="app">
    <div class="bg-gradient top"></div>
    <Menu />
    <!-- <b-container class="route-container">
    </b-container> -->
      <router-view class="ca-route"/>
    <Footer />
    <div class="bg-gradient"></div>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Menu from "./components/Menu.vue";

export default {
  name: "App",
  components: {
    Footer,
    Menu,
  },
  setup() {
    document.title = 'Critical Acclaim'
    return {};
  },
};
</script>

<style>
body {
  /* background-image: url("assets/band_landscape.png"); */
  background-attachment: fixed;
  background-size: cover;
  background-color: black;
  background-position: top;
  background-repeat: no-repeat;
  /* -webkit-box-shadow: inset 0 -80px 80px 0px black;
    -moz-box-shadow: inset 0 -80px 80px 0px black;
    box-shadow: inset 0 -80px 80px 0px white; */
}

.bg-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('assets/bg.png'); */
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-color: black;
  opacity: 0.5;
}
#app {
  /* font-family: Noto Sans, Verdana, Avenir, Helvetica, Arial, sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;

  /* color: #2c3e50; */
  color: white;

  position: absolute;
  width: 100%;
  height: 100%;
}
.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: center;
}
h1 {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.navbar.bg-dark {
  background-color: transparent !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 1);
}
.navbar-collapse.collapse.show {
  background-color: black;
  opacity: 0.9;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.navbar-toggler {
  border: 0;
}
a,
a:hover {
  color: white;
}
.bg-gradient {
  position: fixed;
  height: 7rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(transparent, black);
  z-index: 500;
}
.bg-gradient.top {
  background-image: linear-gradient(to top, transparent, black);
  top: 0;
}
.route-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ca-route {
  padding-top: 15%;
  padding-bottom: 15%;
  padding-left: 30px;
  padding-right: 30px;
}

/* xs */
@media screen and (max-width: 576px) {
  body {
    background-image: url("assets/band_portrait.webp");
    background-size: contain;
  }
}
/* sm */
@media screen and (min-width: 576px) {
  body {
    background-image: url("assets/band_portrait.webp");
  }
}
/* md */
@media screen and (min-width: 720px) {
  body {
    background-image: url("assets/band_portrait.webp");
  }
}
/* lg */
@media screen and (min-width: 960px) {
  body {
    background-image: url("assets/band_portrait.webp");
  }
}
/* xl */
@media screen and (min-width: 1140px) {
  body {
    background-image: url("assets/band_landscape.webp");
  }
  .ca-route {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}
</style>
