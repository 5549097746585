import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import store from '@/store/store'
import { provideStore } from '@/store/use'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import {
  faYoutube,
  faSpotify,
  faFacebookF,
  faInstagram,
  faApple
} from '@fortawesome/free-brands-svg-icons'
library.add(
  faYoutube,
  faSpotify,
  faFacebookF,
  faInstagram,
  faApple,
  faMusic
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)

import News from './components/News.vue'
import Band from './components/Band.vue'
import Contact from './components/Contact.vue'
import Home from './components/Home.vue'
import Live from './components/Live.vue'
import Music from './components/Music.vue'
import Video from './components/Video.vue'

const routes = [
  { name: 'news', path: '/news', component: News },
  { name: 'band', path: '/band', component: Band },
  { name: 'contact', path: '/contact', component: Contact },
  { name: 'home', path: '/', component: Home },
  { name: 'live', path: '/live', component: Live },
  { name: 'music', path: '/music', component: Music },
  { name: 'video', path: '/video', component: Video },
]
const router = new VueRouter({ mode: 'history', routes })

new Vue({
  setup() {
    provideStore(store)
  },
  router,
  render: h => h(App),
}).$mount('#app')
