<template>
    <b-container>
        <b-row>
            <b-col cols="12"><h1>{{ locales.get("news.label")}}</h1></b-col>
        </b-row>
        <b-row class="news-row" v-for="(item, idx) in items" :key="`news-item-${idx}`">
            <b-col cols="12">{{ locales.formatDate(new Date(item.date)) }}</b-col>
            <b-col cols="12">{{ item.message }}</b-col>
            <b-col cols="12" class="news-img-container">
                <b-img
                    class="news-img"
                    v-for="image in item.images"
                    :key="`news-image-${item.date}-${image}`"
                    :src="`/news/${item.date}/${image}.webp`"
                    />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
        const locales = useLocales()

        const items = locales.get('news.items').map((m, i) => {
            for (let k in m) {
                m[k] = locales.get(`news.items[${i}].${k}`)
            }
            return m
        })

        return {
            locales,
            items
        }
    }
}
</script>

<style scoped>
.news-row {
    margin-bottom: 3rem;
}
.news-row::after {
    /* background-color: rgba(255, 255, 255, 0.2); */
    content: '';
    min-height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    min-width: 100%;
    align-self: center;
    margin-top: 5rem;
}
.news-row:last-of-type::after {
    display: none;
}
.news-img-container {
    margin-top: .5rem;
}
.news-img {
    max-height: 300px;
    max-width: 100%;
    margin: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
</style>