<template>
    <b-container>
        <b-row>
            <b-col cols="12"><h1>{{ locales.get("live.label")}}</h1></b-col>
            <b-col cols="12">
                <b-row align-v="center" class="event" v-for="(event, eIdx) in events" :key="'event-'  + eIdx">
                    <b-col class="date" cols="4" sm="3" offset-sm="2">{{ locales.formatDate(new Date(event.date)) }}</b-col>
                    <b-col class="description" cols="6" sm="4">{{ event.description }}</b-col>
                    <b-col class="link" cols="2" sm="1"><b-link v-if="event.link" :href="event.link" target="_blank"><b-icon-map/></b-link></b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
        const locales = useLocales()
        
        const events = locales.get('live.events').map((e, i) => {
            for (let k in e) {
                e[k] = locales.get(`live.events[${i}].${k}`)
            }
            return e
        }).filter(event => {
            let now = new Date()
            now.setDate(now.getDate() - 1)
            return new Date(event.date) > now
        }).sort((a, b) => {
            return new Date(a.date) - new Date(b.date)
        })

        return {
            events,
            locales
        }
    }
}
</script>
<style scoped>
.event {
    text-align: left;
    margin-bottom: 3rem;
}
.event .date {
    text-align: right;
    white-space: nowrap;
}
</style>