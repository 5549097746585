<template>
    <div class="home">
        <div class="clickable-container">
            <b-link class="clickable-part" v-for="(item, i) in items" :key="'hcl-' + i" :to="item.to">
                {{ item.description }}
            </b-link>
        </div>
    </div>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
      const locales = useLocales()
        
        const routes = ['news', 'band', 'live', 'music', 'video', 'contact']
        const items = routes.map(r => {
            return {
            description: locales.get(`${r}.label`),
            to: r
            }
        })
        return {
            items
        }
    }
}
</script>

<style scoped>
    .clickable-container {
        flex-direction: column;
        text-transform: uppercase;
    }
    .clickable-part {
        flex-grow: 0;
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 1.2rem;
        font-weight: 600;
    }
    
    .clickable-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        align-content: stretch;
        min-height: 100%;
        letter-spacing: .6rem;
    }
    .clickable-part {
        color: white;
        flex-grow: 0;
        background-color: rgba(255, 255, 255, 0);
        display: flex;
        padding-top: 5%;
        padding-bottom: 5%;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;
        -webkit-transition: all 0.7s ease;
        -moz-transition: all 0.7s ease;
        transition: all 0.7s ease;
        font-size: 1.2rem;
    }
    .clickable-part:hover {
        /* background-color: rgba(244, 244, 244, 0.2); */
        background-color: rgba(0, 0, 0, 0.342);
        cursor: pointer;
        -webkit-transition: all 0.7s ease;
        -moz-transition: all 0.7s ease;
        transition: all 0.7s ease;
        text-decoration: none;
        font-size: 2.2rem;
        padding-top: 10%;
    }

    @media screen and (min-width: 1140px) {
        .clickable-container {
            flex-direction: row;
        }
        .clickable-part {
            flex-grow: 1;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 2rem;
            padding-top: 20%;
        }
    }
</style>