import { useStore } from '@/store/use'

export function useLocales () {
    const store = useStore()

    function formatDate (date) {
        return new Intl.DateTimeFormat(`${store.getters.lang}-${store.getters.country}`).format(date)
    }

    function get(key) {
        let s = key.replace(/\[(\w+)\]/g, '.$1')
        s = s.replace(/^\./, '')
        const a = s.split('.')
        let o = store.getters.locales
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i]
            if (k in o) {
                o = o[k]
            } else {
                return key
            }
        }
        if (o[store.getters.lang]) {
            return o[store.getters.lang]
        }
        return o
    }

    return {
        formatDate,
        get
    }
}