<template>
    <b-navbar class="footer" small :toggleable="false" type="dark" variant="dark" fixed="bottom">
    
      <b-navbar-nav class="navbar-expand">
        <b-nav-item v-for="(soc, i) in social" :key="'footer-soc-' + i" :href="soc.link" target="_blank">
          <font-awesome-icon :icon="soc.icon" />
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto navbar-expand">
        <b-nav-item :href="'tel:' + locales.get('contact.phone')"><b-icon-telephone-fill/></b-nav-item>
        <b-nav-item  :href="'mailto:' + locales.get('contact.mail')"><b-icon-envelope-fill/></b-nav-item>
      </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { useLocales } from '@/store/locales/use'

export default {
    setup() {
      const locales = useLocales()
        const social = locales.get("contact.social").map((s, i) => {
            for (let k in s) {
                s[k] = locales.get(`contact.social[${i}].${k}`)
            }
            return s
        })
        
        return {
          locales,
            social
        }
    }
}
</script>

<style>
.footer .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
</style>