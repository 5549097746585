import { provide, inject } from '@vue/composition-api'

const StoreSymbol = Symbol('ca-store')

export function provideStore (store) {
  provide(StoreSymbol, store)
}

export function useStore () {
  return inject(StoreSymbol)
}